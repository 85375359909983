<template>
  <div class="basicBox">
    <div class="pageName">描述品牌故事</div>
    <div class="pageName">
        <p>模板标题</p>
        <el-input type="text" placeholder="请输入内容" v-model="brand_story.title"></el-input>
    </div>
    <Rich :width="540" :height="500" @soninfo="richContant" :richTxt="brand_story.content"></Rich>
  </div>
</template>

<script>
import Rich from '@/components/richText'
export default {
  props: ['brand_story'],
  components:{
      Rich
  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
        resetData(attr){
            this.$emit('reset-data',{
                data:'notice',
                attr
            })
        },
        richContant(val) {
            this.brand_story.content = val
        }
    },
}
</script>

<style lang="less" scoped>
.basicBox {
  padding: 30px;
  .pageName {
    display: flex;
    margin-bottom: 20px;

    p {
      width: 120px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    .el-input {
      max-width: 300px;
    }
    .el-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .el-checkbox {
        margin: 0 10px 10px 0;
      }
    }
    .el-color-picker {
      width: 200px;
      & /deep/ .el-color-picker__trigger {
        width: 100%;
      }
    }
  }
}
</style>
